import reviews1 from '../../../img/reviews-1.jpg';
import reviews2 from '../../../img/reviews-2.jpg';
import reviews3 from '../../../img/reviews-3.jpg';

export const data = [
  {
    id: 1,
    name: 'Ксения',
    type: 'Дизайн логотипа',
    review:
      'Хочу выразить благодарность компании за отличный дизайн моего логотипа. Профессиональный подход, внимание к деталям и быстрая работа. Буду обращаться еще!',
    img: reviews1,
  },
  {
    id: 2,
    name: 'Роман ',
    type: 'Разработка сайта под ключ, онлайн продвижение',
    review:
      'Работал с этой компанией над созданием сайта для своего бизнеса – результат превзошел все мои ожидания! Профессиональный дизайн, удобная навигация и эффективное онлайн продвижение. Очень рекомендую!',
    img: reviews2,
  },
  {
    id: 3,
    name: 'Евгений',
    type: 'Seo оптимизация',
    review:
      'Заказывала у компании услуги digital маркетинга для своего магазина. Результаты просто поражают – увеличение трафика на сайт и увеличение продаж! Очень довольна сотрудничеством.',
    img: reviews3,
  },
  {
    id: 4,
    name: 'Антон',
    type: 'Дизайн логотипа',
    review:
      'Хочу выразить благодарность компании за отличный дизайн моего логотипа. Профессиональный подход, внимание к деталям и быстрая работа. Буду обращаться еще!',
    img: reviews1,
  },
  {
    id: 5,
    name: 'Алеся',
    type: 'Seo оптимизация',
    review:
      'Заказывала у компании услуги digital маркетинга для своего магазина. Результаты просто поражают – увеличение трафика на сайт и увеличение продаж! Очень довольна сотрудничеством.',
    img: reviews3,
  },
];
