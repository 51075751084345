import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import PropTypes from 'prop-types';

export default function CarouselItem({ data, number }) {
  return (
    <Swiper
      className={`slider slider__list slider__list-${number}`}
      slidesPerView={'auto'}
      effect={'coverflow'}
      grabCursor={true}
      loop={true}
      modules={[Navigation]}
    >
      {data.map((el, index) => (
        <SwiperSlide
          className={`slider__item slider__item-${el.type}`}
          key={index}
        >
          <div className={`slider__item__info slider__item__info-${index}`}>
            <div className="slider__item__title">{el.title}</div>
            <div className="slider__item__price"> От {el.price} ₽</div>
            <button className="slider__item__btn">Подробнее</button>
          </div>
          {el.type === 'big' ? (
            <div className="slider__item__img">
              <img className="slider__img" src={el.img} alt="keyboard" />
            </div>
          ) : (
            ''
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

CarouselItem.propTypes = {
  data: PropTypes.array,
  number: PropTypes.string,
};
