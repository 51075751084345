import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Nav({ setIsActive }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let elem = document.getElementById(location.hash.slice(1));
    setTimeout(() => {
      if (location.pathname !== '/portfolio' && elem && elem !== '#why-us') {
        elem.scrollIntoView({ behavior: 'smooth' });
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
    }, 500);
  }, [location]);

  return (
    <ul className="nav__list">
      <li
        className="nav__item active"
        onClick={() => {
          setIsActive !== null ? setIsActive(false) : '';
        }}
      >
        <Link
          exact="true"
          spy={'true'}
          smooth={'true'}
          className="nav__link"
          to={{ pathname: '/', state: { scroll: true } }}
        >
          Главная
        </Link>
      </li>
      <li
        className="nav__item"
        onClick={() => {
          setIsActive !== null ? setIsActive(false) : '';
          navigate('/service');
        }}
      >
        <Link className="nav__link">Услуги</Link>
      </li>
      <li
        className="nav__item"
        onClick={() => {
          setIsActive !== null ? setIsActive(false) : '';
        }}
      >
        <Link
          spy={'true'}
          smooth={'true'}
          className="nav__link"
          to={{ pathname: '/', hash: '#about', state: { scroll: true } }}
        >
          О нас
        </Link>
      </li>
      <li
        className="nav__item"
        onClick={() => {
          setIsActive !== null ? setIsActive(false) : '';
          navigate('/portfolio');
        }}
      >
        <Link spy={'true'} smooth={'true'} className="nav__link">
          Портфолио
        </Link>
      </li>
      <li
        className="nav__item nav__item-why-us"
        onClick={() => {
          setIsActive !== null ? setIsActive(false) : '';
        }}
      >
        <Link
          spy={'true'}
          smooth={'true'}
          className="nav__link"
          to={{ pathname: '/', hash: '#why-us', state: { scroll: true } }}
        >
          Почему мы
        </Link>
      </li>
      <li
        className="nav__item nav__item-reviews"
        onClick={() => {
          setIsActive !== null ? setIsActive(false) : '';
        }}
      >
        <Link
          spy={'true'}
          smooth={'true'}
          className="nav__link"
          to={{ pathname: '/', hash: '#reviews', state: { scroll: true } }}
        >
          Отзывы
        </Link>
      </li>
      <li
        className="nav__item"
        onClick={() => {
          setIsActive !== null ? setIsActive(false) : '';
        }}
      >
        <Link
          spy={'true'}
          smooth={'true'}
          className="nav__link"
          to={{ pathname: '/', hash: '#contacts', state: { scroll: true } }}
        >
          Контакты
        </Link>
      </li>
    </ul>
  );
}
