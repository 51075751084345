import PropTypes from 'prop-types';

export default function ContentItem({ content, index }) {
  return (
    <ul className="content-item" key={index}>
      {content.map((el, index) => {
        return <li key={index}>{el}</li>;
      })}
    </ul>
  );
}

ContentItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
};
