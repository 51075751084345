import icons1 from '../../../img/partners-icons-1.png';
import icons2 from '../../../img/partners-icons-2.png';

export const data = [
  {
    id: 1,
    img: icons1,
  },
  {
    id: 2,
    img: icons2,
  },
  {
    id: 3,
    img: icons1,
  },
  {
    id: 4,
    img: icons2,
  },
  {
    id: 5,
    img: icons1,
  },
  {
    id: 6,
    img: icons2,
  },
  {
    id: 7,
    img: icons1,
  },
  {
    id: 8,
    img: icons2,
  },
  {
    id: 9,
    img: icons1,
  },
  {
    id: 10,
    img: icons2,
  },
  {
    id: 11,
    img: icons1,
  },
  {
    id: 12,
    img: icons2,
  },
];
