import { useState } from 'react';
import Nav from './Nav/Nav';
import './header.scss';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Modal from '../Main/Modal';
import FormElement from '../Main/SubmitApplication/FormElement/FormElement';

export default function Header() {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [modal, setModal] = useState(false);

  return (
    <header className="header">
      <div className="container">
        <div className="glare__list">
          <div className="glare glare__left"></div>
          <div className="glare glare__right"></div>
        </div>

        <div className="header__wrapper header__wrapper-sm">
          <div className="header__wrapper__box">
            <NavLink
              to="/"
              className="header__logo"
              onClick={() => navigate('/')}
            >
              {' '}
            </NavLink>
            <button
              className={`header__navbar__btn ${isActive ? 'active' : ''}`}
              onClick={() => {
                setIsActive(!isActive);
              }}
            >
              <span className="header__navbar__btn-label"></span>
            </button>
          </div>
          <div className="header__nav__box">
            <nav className={`nav header__nav ${isActive ? 'active' : ''}`}>
              <Nav setIsActive={setIsActive} />
            </nav>
          </div>
          <button
            className="header__btn light__button"
            onClick={() => setModalActive(true)}
          >
            Связаться с нами
          </button>
        </div>
        <Modal
          active={modalActive}
          setActive={setModalActive}
          setModal={setModal}
          type={'modal'}
        >
          {!modal ? (
            <FormElement type={'modal'} setModal={setModal} />
          ) : (
            <>
              <h3 className="modal__text">
                Спасибо за заявку. Мы скоро свяжемся с Вами!
              </h3>
              <button
                className="modal__btn__close dark__button"
                onClick={() => {
                  setModalActive(false);
                  setModal(false);
                }}
              >
                Закрыть
              </button>
            </>
          )}
        </Modal>
      </div>
    </header>
  );
}
