export const data = {
  offersList: [
    {
      id: 1,
      header: 'Лэндинг',
      description: [
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam facilis voluptates, excepturi vel quisquam adipisci abperspiciatis, sint, amet veritatis consectetur laudanti voluptatibus. Quam eius delectus magni nam voluptatibus consequatur!',
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam facilis voluptates, excepturi vel quisquam adipisci abperspiciatis, sint, amet veritatis consectetur laudanti voluptatibus. Quam eius delectus magni nam voluptatibus consequatur!',
        ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Namfacilis voluptates, excepturi vel quisquam adipisci abperspiciatis, sint, amet veritatis consectetur laudantiumvoluptatibus.',
      ],
    },
    {
      id: 2,
      header: 'Сайт для бизнеса',
      description: [
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam facilis voluptates, excepturi vel quisquam adipisci abperspiciatis, sint, amet veritatis consectetur laudanti voluptatibus. Quam eius delectus magni nam voluptatibus consequatur!',
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam facilis voluptates, excepturi vel quisquam adipisci abperspiciatis, sint, amet veritatis consectetur laudanti voluptatibus. Quam eius delectus magni nam voluptatibus consequatur!',
        ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Namfacilis voluptates, excepturi vel quisquam adipisci abperspiciatis, sint, amet veritatis consectetur laudantiumvoluptatibus.',
      ],
    },
    {
      id: 3,
      header: 'Сайт каталог',
      description: [
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam facilis voluptates, excepturi vel quisquam adipisci abperspiciatis, sint, amet veritatis consectetur laudanti voluptatibus. Quam eius delectus magni nam voluptatibus consequatur!',
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam facilis voluptates, excepturi vel quisquam adipisci abperspiciatis, sint, amet veritatis consectetur laudanti voluptatibus. Quam eius delectus magni nam voluptatibus consequatur!',
        ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Namfacilis voluptates, excepturi vel quisquam adipisci abperspiciatis, sint, amet veritatis consectetur laudantiumvoluptatibus.',
      ],
    },
    {
      id: 4,
      header: 'Интернет магазин',
      description: [
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam facilis voluptates, excepturi vel quisquam adipisci abperspiciatis, sint, amet veritatis consectetur laudanti voluptatibus. Quam eius delectus magni nam voluptatibus consequatur!',
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam facilis voluptates, excepturi vel quisquam adipisci abperspiciatis, sint, amet veritatis consectetur laudanti voluptatibus. Quam eius delectus magni nam voluptatibus consequatur!',
        ' Lorem ipsum dolor sit amet consectetur adipisicing elit. Namfacilis voluptates, excepturi vel quisquam adipisci abperspiciatis, sint, amet veritatis consectetur laudantiumvoluptatibus.',
      ],
    },
  ],
};
