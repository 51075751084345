import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.scss';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Main from './components/Main/Main';
import ServicePage from './components/ServicePage/ServicePage';

const Portfolio = lazy(() => import('../src/components/Portfolio/Portfolio'));

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route
            path="portfolio"
            element={
              <Suspense fallback={<div>Loading ...</div>}>
                <Portfolio />
              </Suspense>
            }
          />
          <Route
            path="service"
            element={
              <Suspense fallback={<div>Loading ...</div>}>
                <ServicePage />
              </Suspense>
            }
          />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
