import keyboardOne from '../../../img/slider-keyboard.jpg';
import computerOne from '../../../img/slider-computer.jpg';
import keyboardTwo from '../../../img/slider-keyboard-two.jpg';
import computerTwo from '../../../img/slider-computer-two.jpg';

export const data = [
  {
    listone: [
      {
        id: 1,
        title: 'Реклама через сервисы Яндекс',
        price: 30000,
        type: 'big',
        img: keyboardOne,
      },
      {
        id: 2,
        title: 'Разработка сайтов Wordpress',
        price: 30000,
        type: 'small',
      },
      {
        id: 3,
        title: 'Разработка сайтов Wordpress',
        price: 30000,
        type: 'small',
      },
      {
        id: 4,
        title: 'Разработка дизайна web приложений',
        price: 30000,
        type: 'big',
        img: computerOne,
      },
      {
        id: 1,
        title: 'Реклама через сервисы Яндекс',
        price: 30000,
        type: 'big',
        img: keyboardOne,
      },
      {
        id: 2,
        title: 'Разработка сайтов Wordpress',
        price: 30000,
        type: 'small',
      },
      {
        id: 3,
        title: 'Разработка сайтов Wordpress',
        price: 30000,
        type: 'small',
      },
      {
        id: 4,
        title: 'Разработка дизайна web приложений',
        price: 30000,
        type: 'big',
        img: computerOne,
      },
    ],
    listtwo: [
      {
        id: 1,
        title: 'Реклама через сервисы Яндекс',
        price: 30000,
        type: 'big',
        img: keyboardTwo,
      },
      {
        id: 2,
        title: 'Разработка сайтов Wordpress',
        price: 30000,
        type: 'small',
      },
      {
        id: 3,
        title: 'Разработка сайтов Wordpress',
        price: 30000,
        type: 'small',
      },
      {
        id: 4,
        title: 'Разработка дизайна web приложений',
        price: 30000,
        type: 'big',
        img: computerTwo,
      },
      {
        id: 1,
        title: 'Реклама через сервисы Яндекс',
        price: 30000,
        type: 'big',
        img: keyboardOne,
      },
      {
        id: 2,
        title: 'Разработка сайтов Wordpress',
        price: 30000,
        type: 'small',
      },
      {
        id: 3,
        title: 'Разработка сайтов Wordpress',
        price: 30000,
        type: 'small',
      },
      {
        id: 4,
        title: 'Разработка дизайна web приложений',
        price: 30000,
        type: 'big',
        img: computerOne,
      },
    ],
  },
];
