import vk from '../../img/social-vk.svg';
import whatsapp from '../../img/social-whatsapp.svg';
import telegram from '../../img/social-telegram.svg';

export const data = {
  socialNetwork: [
    {
      id: 1,
      img: vk,
      title: 'vk',
      link: 'https://vk.com',
    },
    {
      id: 2,
      img: whatsapp,
      title: 'whatsapp',
      link: 'https://web.whatsapp.com/',
    },
    {
      id: 3,
      img: telegram,
      title: 'telegram',
      link: 'https://t.me/telegram',
    },
  ],
  menu: [
    {
      id: 1,
      title: 'Главная',
      type: 'home',
      hash: null,
    },
    {
      id: 2,
      title: 'О нас',
      type: 'about-us',
      hash: '#about',
    },
    {
      id: 3,
      title: 'Услуги',
      type: 'services',
      hash: '#services',
    },
    {
      id: 4,
      title: 'Портфолио',
      type: 'portfolio',
      hash: '#portfolio',
    },
    {
      id: 5,
      title: 'Почему мы',
      type: 'why-us',
      hash: '#why-us',
    },
    {
      id: 6,
      title: 'Отзывы',
      type: 'reviews',
      hash: '#reviews',
    },
    {
      id: 7,
      title: 'Контакты',
      type: 'contacts',
      hash: '#contacts',
    },
  ],
  service: [
    {
      id: 1,
      title: 'Разработка сайтов',
      contentone: ['Backend', 'YII2', 'PHP', 'Wordpress', 'Laravel'],
      contenttwo: ['Fronten', 'Vue.js', 'React', 'HTML', 'JavaScript'],
    },
    {
      id: 2,
      title: 'Дизайн',
      contentone: ['Backend', 'YII2', 'PHP', 'Wordpress', 'Laravel'],
      contenttwo: ['Fronten', 'Vue.js', 'React', 'HTML', 'JavaScript'],
    },
    {
      id: 3,
      title: 'Интернет маркетинг',
      contentone: ['Backend', 'YII2', 'PHP', 'Wordpress', 'Laravel'],
      contenttwo: ['Fronten', 'Vue.js', 'React', 'HTML', 'JavaScript'],
    },
    {
      id: 4,
      title: 'Интернет продвижение',
      contentone: ['Backend', 'YII2', 'PHP', 'Wordpress', 'Laravel'],
      contenttwo: ['Fronten', 'Vue.js', 'React', 'HTML', 'JavaScript'],
    },
    {
      id: 5,
      title: 'SEO продвижение',
      contentone: ['Backend', 'YII2', 'PHP', 'Wordpress', 'Laravel'],
      contenttwo: ['Fronten', 'Vue.js', 'React', 'HTML', 'JavaScript'],
    },
  ],
};
