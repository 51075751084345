import './mixService.scss';
import AccordionItem from './AccordionItem/AccordionItem';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';

export default function MixService() {
  const [accordion, setAccorion] = useState(0);
  const { ref: service, inView: elem } = useInView({
    threshold: 0,
    rootMargin: '100px 0px 100px 0px',
    triggerOnce: true,
  });

  return (
    <div className="mix-service" ref={service}>
      {elem ? (
        <>
          <div className="mix-service-img"></div>
          <div className="container">
            <div className="title__list">
              <h2 className="title__item">
                Выведи свой бизнес <span>На новый уровень!</span>
              </h2>
            </div>

            <div className="accordion">
              <AccordionItem setAccorion={setAccorion} accordion={accordion} />
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
