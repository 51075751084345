import ReactPaginate from 'react-paginate';

export default function Pagination({
  setCurrentPage,
  currentPages,
  totalPages,
}) {
  const showNextButton = currentPages !== totalPages - 1;
  const showPrevButton = currentPages !== 0;
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div>
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          showNextButton ? (
            <>
              {' '}
              <div className="page__link__next">
                <div className="page__link__next-human"></div>
              </div>
            </>
          ) : null
        }
        pageCount={totalPages}
        previousLabel={
          showPrevButton ? (
            <>
              <div className="page__link__previous"></div>
              <div className="page__link__previous-human"></div>
            </>
          ) : null
        }
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        containerClassName="pagination"
        pageClassName="page__item"
        pageLinkClassName="page__link"
        previousClassName="page__item page__link-previous"
        previousLinkClassName="link-previous"
        nextClassName="page__item page__link-next"
        nextLinkClassName="link-next"
        breakClassName="page__item"
        breakLinkClassName="page__link"
        activeClassName="active"
        onPageChange={handlePageClick}
        disabledClassName="pagination__disabled"
      />
    </div>
  );
}
