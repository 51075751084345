export const data = [
  {
    listone: [
      {
        id: 1,
        title: 'Дизайн',
        description: 'Web дизайн интернет проектов',
      },
      {
        id: 2,
        title: 'Разработка  Cайтов',
        description: 'Backend на Wordpress',
      },
    ],
  },
  {
    listtwo: [
      {
        id: 3,
        title: 'Дизайн под ключ',
        description: 'Логотипы',
      },
      {
        id: 4,
        title: 'SEO',
        description: 'Поисковая система Яндекс',
      },
    ],
  },
];
