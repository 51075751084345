import './footer.scss';
import { Link } from 'react-router-dom';
import { data } from './data';
import AccordionItem from '../Main/MixService/AccordionItem/AccordionItem';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import FormElement from '../Main/SubmitApplication/FormElement/FormElement';
import { useNavigate } from 'react-router-dom';
import Modal from '../Main/Modal';
import Nav from '../Header/Nav/Nav';

export default function Footer() {
  const [accordion, setAccorion] = useState(null);
  const [setModalActive] = useState(false);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  return (
    <footer className="footer">
      <div className="container">
        <NavLink
          id="contacts"
          to="/"
          onClick={() => navigate('/')}
          className="footer__logo"
        ></NavLink>
        <div className="footer__wrapper">
          <div className="footer__info">
            <div className="footer__titles">
              <h2 className="footer__title-contacts"> Контакты</h2>
              <h3 className="footer__title-nav">Меню</h3>
              <h3 className="footer__title-categories">Категории</h3>
            </div>
            <div className="footer__info__discription">
              <div className="footer__contacts__info">
                <div className="footer__contacts footer__contacts__list">
                  <p className="footer__contacts__address footer__contacts__item">
                    350831, г. Краснодар, ул. Строительная, 1, оф. 23
                  </p>
                  <p className="footer__contacts__telephone footer__contacts__item">
                    Cвяжись с нами: +7 978 246-40-42
                  </p>
                  <p className="footer__contacts__mail footer__contacts__item">
                    Напиши нам: intoponline@yandex.ru
                  </p>
                </div>
                <div className="social__icons__list">
                  {data.socialNetwork.map((el, index) => {
                    return (
                      <div className="social__icons__item" key={index}>
                        <Link
                          to={el.link}
                          className={`icons__link icons__link-${el.title}`}
                        >
                          <img
                            className="icons__link__img "
                            src={el.img}
                            alt="icon"
                          />
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="footer__nav">
                <div className="footer__nav__list">
                  <Nav setIsActive={null} />
                </div>
              </div>

              <div className="footer__categories">
                <div className="accordion footer__accordion">
                  <AccordionItem
                    setAccorion={setAccorion}
                    accordion={accordion}
                  />
                </div>
              </div>
            </div>
          </div>
          <FormElement type={'footer'} setModal={setModal} />
          <Modal
            active={modal}
            setActive={setModalActive}
            setModal={setModal}
            type={'modal'}
          >
            <h3 className="modal__text">
              Спасибо за заявку. Мы скоро свяжемся с Вами!
            </h3>
            <button
              className="modal__btn__close dark__button"
              onClick={() => {
                setModal(false);
              }}
            >
              Закрыть
            </button>
          </Modal>
        </div>
      </div>
    </footer>
  );
}
