import img1 from '../../../img/projects-item-1.jpg';
import img2 from '../../../img/projects-item-2.jpg';
import img3 from '../../../img/projects-item-3.jpg';
import img4 from '../../../img/projects-item-4.jpg';
import img5 from '../../../img/projects-item-5.jpg';
import img6 from '../../../img/projects-item-6.jpg';
import img7 from '../../../img/projects-item-7.jpg';
import pageortfolio1 from '../../../img/portfolio/portfolio-1.1.jpg';
import pageortfolio2 from '../../../img/portfolio/portfolio-1.2.jpg';
import pageortfolio3 from '../../../img/portfolio/portfolio-1.3.jpg';

export const data = [
  {
    category: [
      {
        id: 1,
        type: 'development',
        name: 'Разработка сайтов',
      },
      {
        id: 2,
        type: 'design',
        name: 'Дизайн',
      },
      {
        id: 3,
        type: 'promotion',
        name: 'Реклама через сервисы Яндекс',
      },
      {
        id: 4,
        type: 'seo',
        name: 'SEO-продвижение',
      },
    ],
    cardCategory: {
      design: {
        id: 1,
        name: 'Дизайн',
        items: [
          {
            id: 1,
            title: 'Landing page',
            description:
              'Целевая страница для ПО помогающего управлять интернет клубом Целевая страница для ПО помогающего управлять интернет клубом Целевая страница для ПО помогающего управлять интернет клубом',
            img: img1,
            type: 'design',
            imgPortfolio: [pageortfolio1],
          },
          {
            id: 2,
            title: 'Landing page',
            description: 'Посадочная страница для интернет провайдера',
            img: img2,
            type: 'design',
            imgPortfolio: [pageortfolio2],
          },
          {
            id: 3,
            title: 'Web-Сайт',
            description:
              'Вебсайт поиска жилья и гостиниц возле моря в Крыму, Абхазии и России',
            img: img3,
            type: 'design',
            imgPortfolio: [pageortfolio3],
          },
          {
            id: 4,
            title: 'Web-Сайт',
            description: 'Вебсайт для отеля',
            img: img4,
            type: 'design',
            imgPortfolio: [pageortfolio1],
          },
          {
            id: 5,
            title: 'Web-Сайт',
            description: 'Сайт для различных аюрведических процедур и СПА',
            img: img5,
            type: 'design',
            imgPortfolio: [pageortfolio2],
          },
          {
            id: 6,
            title: 'Landing page',
            description: 'Посадочная страница устричной фермы',
            img: img6,
            type: 'design',
            imgPortfolio: [pageortfolio3],
          },
          {
            id: 7,
            title: 'Web-Сайт',
            description: 'Веб сайт для поиска жилья и отелей по всей Росси',
            img: img7,
            type: 'design',
            imgPortfolio: [pageortfolio1],
          },
          {
            id: 8,
            title: 'Landing page',
            description:
              'Посадочная страница для продажи курсов различных онлайн школ',
            img: img1,
            type: 'design',
            imgPortfolio: [pageortfolio1],
          },
          {
            id: 6,
            title: 'Landing page',
            description: 'Посадочная страница устричной фермы',
            img: img6,
            type: 'design',
            imgPortfolio: [pageortfolio1],
          },
          {
            id: 6,
            title: 'Landing page',
            description: 'Посадочная страница устричной фермы',
            img: img6,
            type: 'design',
            imgPortfolio: [pageortfolio1],
          },
          {
            id: 6,
            title: 'Landing page',
            description: 'Посадочная страница устричной фермы',
            img: img6,
            type: 'design',
            imgPortfolio: [pageortfolio1],
          },
          {
            id: 6,
            title: 'Landing page',
            description: 'Посадочная страница устричной фермы',
            img: img6,
            type: 'design',
            imgPortfolio: [pageortfolio1],
          },
          {
            id: 6,
            title: 'Landing page',
            description: 'Посадочная страница устричной фермы',
            img: img6,
            type: 'design',
            imgPortfolio: [pageortfolio1],
          },
          {
            id: 6,
            title: 'Landing page',
            description: 'Посадочная страница устричной фермы',
            img: img6,
            type: 'design',
            imgPortfolio: [pageortfolio1],
          },
          {
            id: 6,
            title: 'Landing page',
            description: 'Посадочная страница устричной фермы',
            img: img6,
            type: 'design',
            imgPortfolio: [pageortfolio1],
          },
        ],
      },
      seo: {
        id: 2,
        name: 'Сео-продвижение',
        items: [
          {
            id: 1,
            title: 'Landing page',
            description:
              'Целевая страница для ПО помогающего управлять интернет клубом',
            img: img1,
            type: 'seo',
            imgPortfolio: [pageortfolio1],
          },
          {
            id: 2,
            title: 'Landing page',
            description: 'Посадочная страница для интернет провайдера',
            img: img2,
            type: 'seo',
            imgPortfolio: [pageortfolio2],
          },
          {
            id: 3,
            title: 'Web-Сайт',
            description:
              'Вебсайт поиска жилья и гостиниц возле моря в Крыму, Абхазии и России',
            img: img3,
            type: 'seo',
            imgPortfolio: [pageortfolio3],
          },
          {
            id: 4,
            title: 'Web-Сайт',
            description: 'Вебсайт для отеля',
            img: img4,
            type: 'seo',
            imgPortfolio: [pageortfolio1],
          },
          {
            id: 5,
            title: 'Web-Сайт',
            description: 'Сайт для различных аюрведических процедур и СПА',
            img: img5,
            type: 'seo',
            imgPortfolio: [pageortfolio2],
          },
        ],
      },
      development: {
        id: 3,
        name: 'Разработка сайтов',
        items: [
          {
            id: 1,
            title: 'Landing page',
            description:
              'Целевая страница для ПО помогающего управлять интернет клубом',
            img: img4,
            type: 'development',
            imgPortfolio: [pageortfolio1],
          },
          {
            id: 2,
            title: 'Landing page',
            description: 'Посадочная страница для интернет провайдера',
            img: img4,
            type: 'development',
            imgPortfolio: [pageortfolio2],
          },
          {
            id: 3,
            title: 'Web-Сайт',
            description:
              'Вебсайт поиска жилья и гостиниц возле моря в Крыму, Абхазии и России',
            img: img4,
            type: 'development',
            imgPortfolio: [pageortfolio3],
          },
          {
            id: 4,
            title: 'Web-Сайт',
            description: 'Вебсайт для отеля',
            img: img4,
            type: 'development',
            imgPortfolio: [pageortfolio1],
          },
          {
            id: 5,
            title: 'Web-Сайт',
            description: 'Сайт для различных аюрведических процедур и СПА',
            img: img4,
            type: 'development',
            imgPortfolio: [pageortfolio2],
          },
          {
            id: 6,
            title: 'Landing page',
            description: 'Посадочная страница устричной фермы',
            img: img4,
            type: 'development',
            imgPortfolio: [pageortfolio3],
          },
          {
            id: 7,
            title: 'Web-Сайт',
            description: 'Веб сайт для поиска жилья и отелей по всей Росси',
            img: img4,
            type: 'development',
            imgPortfolio: [pageortfolio1],
          },
        ],
      },
      promotion: {
        id: 4,
        name: 'Реклама через сервисы Яндекс',
        items: [
          {
            id: 1,
            title: 'Landing page',
            description:
              'Целевая страница для ПО помогающего управлять интернет клубом',
            img: img7,
            type: 'promotion',
            imgPortfolio: [pageortfolio1],
          },
          {
            id: 2,
            title: 'Landing page',
            description: 'Посадочная страница для интернет провайдера',
            img: img7,
            type: 'promotion',
            imgPortfolio: [pageortfolio2],
          },
          {
            id: 3,
            title: 'Web-Сайт',
            description:
              'Вебсайт поиска жилья и гостиниц возле моря в Крыму, Абхазии и России',
            img: img7,
            type: 'promotion',
            imgPortfolio: [pageortfolio3],
          },
          {
            id: 4,
            title: 'Web-Сайт',
            description: 'Вебсайт для отеля',
            img: img7,
            type: 'promotion',
            imgPortfolio: [pageortfolio1],
          },
          {
            id: 5,
            title: 'Web-Сайт',
            description: 'Сайт для различных аюрведических процедур и СПА',
            img: img7,
            type: 'promotion',
            imgPortfolio: [pageortfolio2],
          },
        ],
      },
    },
  },
];
