import './projects.scss';
import { data } from './data';
import ProjectsItem from './ProjectsItem/ProjectsItem';
import { useEffect, useState } from 'react';
import Modal from '../Modal';
import { useNavigate } from 'react-router-dom';
import FormElement from '../SubmitApplication/FormElement/FormElement';
import Pagination from '../../Portfolio/Pagination/Pagination';
import PropTypes from 'prop-types';

export default function Projects({ casetype, children }) {
  const [category, setCategory] = useState('design');
  const [modalActive, setModalActive] = useState(false);
  const [modal, setModal] = useState(false);
  const [projectsModalActive, setProjectsModalActive] = useState(false);
  const navigate = useNavigate();

  const [pages, setPages] = useState([]);
  const [currentPages, setCurrentPage] = useState(0);
  const totalPages = 8;
  const pagesVisited = currentPages * totalPages;
  const displayPages = pages.slice(pagesVisited, pagesVisited + totalPages);
  const pageCount = Math.ceil(pages.length / totalPages);
  const [activePost, setActivePost] = useState(null);

  useEffect(() => {
    const result = data[0].cardCategory[category].items;
    setPages(result);
  }, [category]);

  return (
    <div className={`projects projects__${casetype}`} id="portfolio">
      {casetype === 'main' ? (
        <div className="projects__blobs">
          <div className="projects__blobs__item blobs-left"></div>
          <div className="projects__blobs__item blobs-rigth"></div>
        </div>
      ) : (
        ''
      )}
      {casetype !== 'main' ? (
        <>
          <div className="title__list">
            <h2 className="title__item">
              Наши <span>работы</span>
            </h2>
          </div>
          <div className="sorting__list__wrapper">
            <ul className="sorting__list">
              {data[0].category.map((el, index) => {
                return (
                  <li
                    className={`sorting__item__link ${category === el.type ? 'active' : ''}`}
                    onClick={() => {
                      setCategory(el.type);
                      setCurrentPage(0);
                    }}
                    key={index}
                  >
                    <div
                      className={`sorting__item sorting__item__${el.type} ${category === el.type ? 'active' : ''}`}
                    >
                      {el.name}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      ) : (
        ''
      )}

      {children}
      <div className="projects-box">
        <div className="container">
          {casetype === 'main' ? (
            <>
              <div className="title__list">
                <h2 className="title__item">
                  Наши <span>кейсы</span>
                </h2>
              </div>
              <div className="sorting__list__wrapper">
                <ul className="sorting__list">
                  {data[0].category.map((el, index) => {
                    return (
                      <li
                        className={`sorting__item__link ${category === el.type ? 'active' : ''}`}
                        onClick={() => setCategory(el.type)}
                        key={index}
                      >
                        <div
                          className={`sorting__item sorting__item__${el.type} ${category === el.type ? 'active' : ''}`}
                        >
                          {el.name}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </>
          ) : (
            ''
          )}

          <div className="projects__list">
            {casetype === 'main' ? (
              <ProjectsItem casetype={'main'} pages={displayPages} />
            ) : (
              <ProjectsItem
                setActive={setProjectsModalActive}
                casetype={'portfolio'}
                pages={displayPages}
                activePost={setActivePost}
              />
            )}
          </div>
          <div className="projects__control">
            {casetype !== 'main' ? (
              <Pagination
                totalPages={pageCount}
                currentPages={currentPages}
                setCurrentPage={setCurrentPage}
              />
            ) : (
              ''
            )}

            {casetype === 'main' ? (
              <button
                className="projects__btn dark__button"
                onClick={() => {
                  navigate('/portfolio');
                }}
              >
                Смотреть все работы
              </button>
            ) : (
              <div className="portfolio__btn-order">
                <button
                  className="btn portfolio__btn light__button"
                  onClick={() => {
                    setModalActive(true);
                  }}
                >
                  Заказать дизайн
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        active={modalActive}
        setActive={setModalActive}
        setModal={setModal}
        type={'modal'}
      >
        {!modal ? (
          <FormElement type={'modal'} setModal={setModal} />
        ) : (
          <>
            <h3 className="modal__text">
              Спасибо за заявку. Мы скоро свяжемся с Вами!
            </h3>
            <button
              className="modal__btn__close dark__button"
              onClick={() => {
                setModalActive(false);
                setModal(false);
              }}
            >
              Закрыть
            </button>
          </>
        )}
      </Modal>
      <Modal
        active={projectsModalActive}
        setActive={setProjectsModalActive}
        setModal={null}
        type={'portfolio'}
      >
        <div className="modal__projects">
          <div className="modal__projects__header">
            <div className="modal__projects__info">
              <h2 className="modal__projects__title">Landing page</h2>
              <p className="modal__projects__text">
                Целевая страница для ПО помогающего управлять интернет клубом
              </p>
            </div>
            <button
              className="modal__projects__btn "
              onClick={() => setProjectsModalActive(false)}
            ></button>
          </div>
          <div className="modal__projects__body">
            <img
              className="modal__projects__img"
              src={activePost !== null ? pages[activePost].imgPortfolio : ''}
              alt="landing_page"
            ></img>
          </div>
        </div>
      </Modal>
    </div>
  );
}

Projects.propTypes = {
  casetype: PropTypes.string,
  children: PropTypes.object,
};
