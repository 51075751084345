import './aboutUs.scss';

export default function AboutUs() {
  return (
    <div className="about__us" id="about">
      <div className="about__us__wave"></div>
      <div className="about__us__wrapper wrapper">
        <div className="container">
          <div className="about__us__wrapper-discription">
            <div className="title__list">
              <h2 className="title__item">О нас</h2>
              <div className="about__us__discription">
                <p>
                  Команда профессионалов по разработке веб-сайтов, дизайну и
                  интернет-маркетингу. Наша цель - помочь вашему бизнесу
                  добиться успеха в онлайн-пространстве.
                </p>
                <p>Решения для бизнеса:</p>
                <p>
                  Мы предлагаем комплексные решения для вашего бизнеса, включая
                  разработку сайтов, уникальный дизайн, интернет-маркетинг и SEO
                  оптимизацию.
                </p>
              </div>
            </div>

            <div className="about__us__imges">
              <div className="blob__list">
                <div className="blob-big"></div>
                <div className="blob-small"> </div>
              </div>
              <picture className="about__us__img-box">
                <source
                  srcSet={require('../../../img/about-us-min-768.jpg')}
                  media="(max-width: 768px)"
                  sizes="768px"
                />
                <img
                  className="about__us__img"
                  src={require('../../../img/about-us.jpg')}
                  alt="company"
                  loading="lazy"
                />
              </picture>
            </div>
          </div>

          <div className="glare__list">
            <div className="glare glare__left"></div>
            <div className="glare glare__right-1"></div>
            <div className="glare glare__right-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
