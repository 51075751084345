import './accordionItem.scss';
import ContentItem from './ContentItem';
import PropTypes from 'prop-types';
import { data } from '../../../Footer/data';
import { useNavigate } from 'react-router-dom';

export default function AccordionItem({ setAccorion, accordion }) {
  const navigate = useNavigate();

  const accordionToggle = (index) => {
    if (accordion === index) {
      return setAccorion(null);
    } else {
      setAccorion(null);
    }
    setAccorion(index);
  };

  return data.service.map((el, index) => {
    return (
      <div
        className={`accordion__item ${accordion === index ? 'active' : ''}`}
        key={index}
        onClick={() => accordionToggle(index)}
      >
        <div className={`lable `}>{el.title}</div>
        <div className="content">
          <div className="content__list">
            <ContentItem content={el.contentone} index={index} />
            {el.contenttwo.length > 0 ? (
              <ContentItem content={el.contenttwo} />
            ) : (
              ''
            )}
          </div>
          <button
            className="content__btn btn__transition__services"
            onClick={() => navigate('/service')}
          >
            На страницу услуги{' '}
          </button>
        </div>
      </div>
    );
  });
}

AccordionItem.propTypes = {
  setAccorion: PropTypes.func,
  accordion: PropTypes.number,
};
