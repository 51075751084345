import './businessSolutions.scss';
import CarouselItem from './CarouselItem';
import { data } from './data';

export default function BusinessSolutions({ type }) {
  return (
    <div className={`business__solutions business__solutions-${type}`}>
      <div className="container-box">
        <div className="title__list">
          <h2 className="title__item">
            Решения <span>для бизнеса</span>
          </h2>
        </div>
        <CarouselItem data={data[0].listone} number={'one'} />
        <CarouselItem data={data[0].listtwo} number={'two'} />
      </div>
    </div>
  );
}
