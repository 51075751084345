import './servicePage.scss';
import Consultation from '../Main/Consultation/Consultation';
import BusinessSolutions from '../../components/Main/BusinessSolutions/BusinessSolutions';
import AdvantagesInfo from './AdvantagesInfo/AdvantagesInfo';
import OffersList from './OffersList/OffersList';

export default function ServicePage() {
  return (
    <>
      <Consultation type="service-page" />
      <AdvantagesInfo />
      <OffersList />
      <BusinessSolutions type={'service'} />
    </>
  );
}
