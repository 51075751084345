import './advantages.scss';
import AdvantagesItem from './AdvantagesItem/AdvantagesItem';

export default function Advantages() {
  return (
    <div className="advantages" id="why-us">
      <div className="container">
        <div className="title__list">
          <h2 className="title__item">Преимущества</h2>
          <h2 className="title__item">работы с нами</h2>
        </div>
        <div className="advantages__list">
          <AdvantagesItem />
        </div>
      </div>
    </div>
  );
}
