import './offersList.scss';
import { data } from './data';

export default function OffersList() {
  return (
    <div className="offers">
      <div className="offers__wave"> </div>
      <div className="container">
        <div className="title__list">
          <h2 className="title__item">
            подберем <span>тип сайта </span>
          </h2>
          <h2 className="title__item">под решение именно ваших бизнес-задач</h2>
        </div>

        <div className="offers__list">
          {data.offersList.map((el, index) => {
            return (
              <div className="offers__item" key={index}>
                <div className="offers__header">{el.header}</div>
                <div className="offers__item-wave"></div>
                <ul>
                  {el.description.map((item, index) => {
                    return (
                      <li key={index}>
                        <p>{item}</p>
                      </li>
                    );
                  })}
                </ul>
                <div className="calculate__btn-box">
                  <button className="calculate__btn dark__button">
                    Получить расчет
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
