import './services.scss';
import { data } from './data';
import ServiceItem from './ServiceItem/ServiceItem';
import { useNavigate } from 'react-router-dom';

export default function Services() {
  const navigate = useNavigate();

  return (
    <div className="services">
      <div className="services-wave"></div>
      <div className="wrapper services__wrapper">
        <div className="container">
          <div className="glare__list">
            <div className="glare glare__left"></div>
            <div className="glare glare__right"></div>
          </div>
          <div className="services__info">
            <div className="blob__list">
              <div className="blob-big"></div>
              <div className="blob-small blob-small-1"> </div>
              <div className="blob-small blob-small-2"></div>
            </div>
            <div className="title__list">
              <h2 className="title__item">Миксуй услуги</h2>
              <h2 className="title__item">Будь в топе!</h2>
            </div>
            <div className="services__card">
              <div className="services__card__text">
                <p>
                  Выберите несколько услуг прямо сейчас, и мы обязательно
                  свяжемся с вами для предоставления профессиональной
                  консультации! Не упустите возможность улучшить свой бизнес
                  прямо сейчас!
                </p>
              </div>
              <div className="services__card__control">
                <button
                  className="services__card__btn dark__button"
                  onClick={() => navigate('/service')}
                >
                  Выбрать услуги
                </button>
              </div>
            </div>
          </div>

          <div className="services__card__list">
            <div className="services__card__item__group">
              <ServiceItem items={data[0].listone} />
            </div>
            <div className="services__card__item__group">
              <ServiceItem items={data[1].listtwo} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
