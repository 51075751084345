import './modal.scss';
import PropTypes from 'prop-types';

export default function Modal({ active, setActive, setModal, type, children }) {
  let eventMuoseUp = false;
  let valueSelect = '';
  return setModal !== null || type === 'modal' ? (
    <div
      className={active ? 'modal active' : 'modal'}
      onClick={(e) => {
        if (e.target.classList.contains('modal') && eventMuoseUp !== true) {
          setActive(false);
          setModal(false);
        }
      }}
      onMouseUp={(e) => {
        if (!e.target.classList.contains('modal')) {
          valueSelect = '';
          eventMuoseUp = true;
        } else {
          eventMuoseUp = false;
        }
      }}
      onSelect={(e) => {
        if (valueSelect !== e.target.value) {
          valueSelect = e.target.value;
          eventMuoseUp = true;
        } else {
          valueSelect = '';
          eventMuoseUp = true;
        }
      }}
    >
      <div
        className={
          active
            ? `modal__content active modal__content_${type}`
            : 'modal__content'
        }
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  ) : (
    <div
      className={active ? 'modal active' : 'modal'}
      onClick={() => setActive(false)}
    >
      <div
        className={
          active
            ? `modal__content active modal__content_${type}`
            : 'modal__content'
        }
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
}

Modal.propTypes = {
  active: PropTypes.any,
  setActive: PropTypes.any,
  setModal: PropTypes.any,
  type: PropTypes.string,
  children: PropTypes.node,
};
