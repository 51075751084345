export default function ProjectsItem({
  setActive,
  casetype,
  pages,
  activePost,
}) {
  return pages.map((el, index) => {
    return casetype === `main` ? (
      <div className="projects__item" key={index}>
        <div className="projects__item-wrapper">
          <img alt="" className="projects__img" src={`${el.img}`} />
          <div className="projects__info">
            <div className="wave"></div>
            <div className="filter__block"></div>
            <div className="projects__info-text">
              <h6 className="projects__title">{el.title}</h6>
              <p className="projects__discription">{el.description}</p>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div
        className="projects__item"
        key={index}
        onClick={() => {
          setActive(true);
          activePost(index);
        }}
      >
        <div className="projects__item-wrapper">
          <img alt="" className="projects__img" src={`${el.img}`} />
          <div className="projects__info">
            <div className="wave"></div>
            <div className="filter__block"></div>
            <div className="projects__info-text">
              <h6 className="projects__title">{el.title}</h6>
              <p className="projects__discription">{el.description}</p>
            </div>
          </div>
        </div>
      </div>
    );
  });
}
