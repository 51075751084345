import hat from '../../../../img/hat.png';
import boy from '../../../../img/boy.png';
import rocket from '../../../../img/rocket.png';
import bulb from '../../../../img/bulb.png';

export const data = [
  {
    id: 1,
    title: 'Опытные специалисты в digital маркетинге',
    discription:
      'Наши опытные специалисты в digital маркетинге следят за последними тенденциями и технологиями, чтобы помочь вашему бизнесу выделиться в онлайн-среде.',
    img: hat,
  },
  {
    id: 2,
    title: 'Индивидуальный подход ',
    discription:
      'Мы применяем индивидуальный подход к разработке сайтов, учитывая потребности и цели вашего бизнеса для создания уникального и функционального веб-пространства.',
    img: boy,
  },
  {
    id: 3,
    title: 'Креативные дизайн-решения',
    discription:
      'Наша команда специалистов по дизайну соберет все ваши идеи и предпочтения, чтобы создать креативные и стильные дизайн-решения, отражающие уникальный характер вашего бренда.',
    img: bulb,
  },
  {
    id: 4,
    title: 'Креативные дизайн-решения',
    discription:
      'Наша команда специалистов по дизайну соберет все ваши идеи и предпочтения, чтобы создать креативные и стильные дизайн-решения, отражающие уникальный характер вашего бренда.',
    img: rocket,
  },
];
