import './consultation.scss';
import { useState } from 'react';
import Modal from '../Modal';
import FormElement from '../SubmitApplication/FormElement/FormElement';

export default function Consultation({ type }) {
  const [modalActive, setModalActive] = useState(false);
  const [modal, setModal] = useState(false);

  return (
    <div className={type}>
      <div className="container">
        <div className="glare__list">
          <div className="glare glare__left"></div>
          <div className="glare glare__right"></div>
        </div>
        <div className={`${type}__box`}>
          <div className={`${type}__info`}>
            <div className={`${type}__text`}>
              <div className="title__list">
                {type === 'consultation' ? (
                  <>
                    <h2 className="title__item">Разработка</h2>
                    <h2 className="title__item">уникальных решений</h2>
                    <h2 className="title__item">для вашего бизнеса!</h2>
                  </>
                ) : (
                  <>
                    <h2 className="title__item">Разработка </h2>
                    <h2 className="title__item">сайтов</h2>
                    <h2 className="title__item">под ключ</h2>
                  </>
                )}
              </div>
              <p className="title__description">
                {type === 'consultation'
                  ? 'Комплексные услуги по интернет-маркетингу, веб-разработке и оптимизации сайтов.'
                  : 'Сайт – лицо бизнеса в интернете. Для продвижения и развития бизнеса он просто необходим: продажа товаровили повышение узнаваемости невозможно без веб-проекта.'}
              </p>

              <div className="consultation__btn__box">
                <button
                  className="consultation__btn dark__button"
                  onClick={() => setModalActive(true)}
                >
                  {type === 'consultation'
                    ? 'Бесплатная консультация'
                    : 'Заказать сайт под ключ'}
                </button>
              </div>
            </div>
          </div>
          <div className={`${type}__img`}>
            {type === 'consultation' ? (
              <>
                <div className="blob"></div>
                <div className="consultation__img-lamp"> </div>
                <div className="consultation__img-computer"></div>
                <div className="consultation__img-grid"> </div>
              </>
            ) : (
              <img
                alt="keyboard"
                src={require('../../../img/slider-keyboard.jpg')}
                className={`${type}__img-keyboard`}
              />
            )}
          </div>
        </div>
        <Modal
          active={modalActive}
          setActive={setModalActive}
          setModal={setModal}
        >
          {!modal ? (
            <FormElement type={'modal'} setModal={setModal} />
          ) : (
            <>
              <h3 className="modal__text">
                Спасибо за заявку. Мы скоро свяжемся с Вами!
              </h3>
              <button
                className="modal__btn__close dark__button"
                onClick={() => {
                  setModalActive(false);
                  setModal(false);
                }}
              >
                Закрыть
              </button>
            </>
          )}
        </Modal>
      </div>
    </div>
  );
}
