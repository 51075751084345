import { useForm } from 'react-hook-form';
// import { DevTool } from '@hookform/devtools';
import { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux'; //установить redux и дописать отправку формы на сервер
// import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function FormElement({ type, setModal }) {
  const form = useForm();
  const { register, handleSubmit, formState, reset, watch } = form;
  const { errors, isSubmitSuccessful } = formState;
  const [isValid, setisValid] = useState(false);
  // const dispatch = useDispatch();
  // const navigete = useNavigate();

  useEffect(() => {
    const subscription = watch((value) => {
      if (
        value.name !== undefined &&
        value.email !== undefined &&
        value.telephone !== undefined
      ) {
        setisValid(true);
      }
      if (value.name === '' && value.email === '' && value.telephone === '') {
        setisValid(false);
      }
    });

    if (isSubmitSuccessful) {
      setisValid(false);
      setModal(true);
      reset();
    }

    return () => subscription.unsubscribe();
  }, [isSubmitSuccessful, reset, watch]);
  console.log(formState, 'formState');
  const onSubmit = () => {
    console.log(isValid); //  если форма валидна и ее можно отправлять
    // dispatch(setPersonalData(form)); //отправка формы - доработать когда будет известно куда отправлять
  };

  if (reset) {
    console.log(form, 'form');
  }

  const PATTERN = /\D/g;
  const getInputNamberValue = (value) => {
    return value.replace(PATTERN, '');
  };

  const handlePhoneInput = (event) => {
    const input = event.target;
    let inputNumberValue = getInputNamberValue(input.value);
    let formattedInputValue = '';

    if (!inputNumberValue) {
      return (input.value = '');
    }

    if (['7', '8', '9'].indexOf(inputNumberValue[0] > -1)) {
      if (inputNumberValue[0] === '9') {
        inputNumberValue = '7' + inputNumberValue;
      }

      const firstSymbols = inputNumberValue[0] === '8' ? '8' : '+7';
      formattedInputValue = firstSymbols + ' ';

      if (inputNumberValue.length > 1) {
        formattedInputValue += '(' + inputNumberValue.substring(1, 4);
      }

      if (inputNumberValue.length >= 5) {
        formattedInputValue += ') ' + inputNumberValue.substring(4, 7);
      }

      if (inputNumberValue.length >= 8) {
        formattedInputValue += '-' + inputNumberValue.substring(7, 9);
      }

      if (inputNumberValue.length >= 10) {
        formattedInputValue += '-' + inputNumberValue.substring(9, 11);
      }
    } else {
      formattedInputValue = '+' + inputNumberValue.substring(0, 16);
    }

    input.value = formattedInputValue;
    console.log(inputNumberValue, ' inputNumberValue');
  };

  const handlePhoneKeyDown = (event) => {
    const input = event.target;
    if (
      event.key === 'Backspace' &&
      getInputNamberValue(input.value).length === 1
    ) {
      input.value = '';
    }

    return input;
  };

  const handlePhonePaste = (event) => {
    const pasted = event.clipboardData || window.clipboardData;
    const input = event.target;
    let inputNumberValue = getInputNamberValue(input.value);

    if (pasted) {
      const pastedText = pasted.getData('Text');
      if (PATTERN.test(pastedText)) {
        input.value = inputNumberValue;
      }
    }
  };

  const onFocus = (event) => {
    event.target.setAttribute('autocomplete', 'off');
  };
  return (
    <section>
      <form
        className={`form ${type}__form`}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="new-password"
      >
        {type === 'footer' ? (
          <>
            <div className="footer__title"> Получи </div>
            <div className="footer__title"> бесплатную консультацию!</div>
          </>
        ) : type === 'modal' ? (
          <div className="modal__title">
            Заполните форму и мы обязательно свяжемся с вами
          </div>
        ) : (
          ''
        )}
        <div className="form__info">
          <div className="form__personal__info personal-name">
            <label htmlFor="name" className="form__lable">
              {type !== 'footer' && type !== 'modal' ? (
                <div className="form__title">Как к вам обращаться</div>
              ) : (
                ''
              )}
              <input
                className={`${type}__form__input input__form input__form-name`}
                placeholder="Ваше имя"
                id={type !== 'footer' && type !== 'modal' ? 'name' : ''}
                type="text"
                autoComplete="new-password"
                onFocus={onFocus}
                {...register('name', {
                  required: {
                    value: true,
                    message: 'Введите имя',
                  },
                  pattern: {
                    value: /^([А-Я]{1}[а-яё]{1,23})$/gm,
                    message:
                      'Имя введено не правильно. Оно должно быть тьлько кирилицей!',
                  },
                  minLength: {
                    value: 2,
                    message: 'Имя должно быть не короче 2 символов',
                  },
                })}
              ></input>
            </label>
          </div>

          <div className="form__personal__info personal-mail">
            <label htmlFor="mail" className="form__lable">
              {type !== 'footer' && type !== 'modal' ? (
                <div className="form__title">E-mail</div>
              ) : (
                ''
              )}
              <input
                className={`${type}__form__input input__form input__form-mail`}
                placeholder="Введите ваш E-mail адресс"
                id={type !== 'footer' && type !== 'modal' ? 'mail' : ''}
                type="email"
                autoComplete="new-password"
                onFocus={onFocus}
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Ведите email',
                  },
                  pattern: {
                    value: /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/gm,
                    message: 'email введен не правильно. Проверьте свой email',
                  },
                })}
              ></input>
            </label>
          </div>

          <div className="form__personal__info personal-telephone">
            <label htmlFor="telephone" className="form__lable">
              {type !== 'footer' && type !== 'modal' ? (
                <div className="form__title">НОМЕР ТЕЛЕФОНА</div>
              ) : (
                ''
              )}
              <input
                className={`${type}__form__input input__form input__form-telephone`}
                placeholder="+7 (XXX) XXX-XX-XX"
                id={type !== 'footer' && type !== 'modal' ? 'telephone' : ''}
                type="tel"
                autoComplete="new-password"
                onFocus={onFocus}
                {...register('telephone', {
                  required: {
                    value: true,
                    message: 'Введите номер телефона',
                  },
                  minLength: {
                    value: 18,
                    message: 'Номер должен состоять из 11 цифр',
                  },
                })}
                onInput={handlePhoneInput}
                onKeyDown={handlePhoneKeyDown}
                onPaste={handlePhonePaste}
              ></input>
            </label>
          </div>
        </div>

        <div className="form__control">
          {errors.name?.message ? (
            <div className="form__clue__text error">{errors.name?.message}</div>
          ) : (
            ''
          )}
          {!errors.name?.message ? (
            <div className="form__clue__text error">
              {errors.email?.message}
            </div>
          ) : (
            ''
          )}
          {!errors.email?.message && !errors.name?.message ? (
            <div className="form__clue__text error">
              {errors.telephone?.message}
            </div>
          ) : (
            ''
          )}

          {type === 'footer' && type !== 'modal' && errors.name?.message ? (
            <div className="form__clue__text error">{errors.name?.message}</div>
          ) : (
            ''
          )}
          {type === 'footer' && type !== 'modal' && !errors.name?.message ? (
            <div className="form__clue__text error">
              {errors.email?.message}
            </div>
          ) : (
            ''
          )}
          {type === 'footer' &&
          type !== 'modal' &&
          !errors.email?.message &&
          !errors.name?.message ? (
            <div className="form__clue__text error">
              {errors.telephone?.message}
            </div>
          ) : (
            ''
          )}
          {type !== 'footer' && type !== 'modal' ? (
            <button className="dark__button">Отправить</button>
          ) : (
            ''
          )}
          {type === 'footer' || type === 'modal' ? (
            <button className="connect__btn light__button">
              Связаться с нами
            </button>
          ) : (
            ''
          )}
        </div>
      </form>

      {/* <DevTool control={control}/> submit__formsubmit__form */}
    </section>
  );
}

FormElement.propTypes = {
  type: PropTypes.string,
  setModal: PropTypes.func,
};
