import './advantagesInfo.scss';

export default function AdvantagesInfo() {
  return (
    <div className="advantages__info">
      <div className="advantages-wave"> </div>
      <div className="container">
        <div className="title__list">
          <h2 className="title__item">Что даст вашему бизнесу</h2>
          <h2 className="title__item">разработка сайта</h2>
        </div>

        <div className="advantages__info__list">
          <div className="advantages__info__item">
            <div className="advantages__title">
              Положительное влияние на имидж
            </div>
            <div className="advantages__description">
              <p>
                За счет удобного юзабилити сайта, проектирования с маркетологом
                и качественного дизайна. На вашем сайте будет удобно купить или
                оставить заявку. Клиенты будут вам благодарны за понятный и
                удобный сайт.
              </p>
            </div>
          </div>

          <div className="advantages__info__item">
            <div className="advantages__title">Увеличение продаж в 2 раза </div>
            <div className="advantages__description">
              <p>
                За счет увеличения конверсии и глубокого погружения в аналитику.
                Используем последние тренды в сайтостроении: адаптацию под
                смартфоны, продающая структура, формы захвата внимания.
              </p>
            </div>
          </div>

          <div className="advantages__info__item">
            <div className="advantages__title">Гарантия результатов </div>
            <div className="advantages__description">
              <p>
                Cотрудничество с нами экономит ваши деньги и время. Учитываем в
                договоре пеню за каждый просроченный день, тем самым всегда
                успеваем в срок.
              </p>
            </div>
          </div>

          <div className="blob__list">
            <div className="blob-big-left"></div>
            <div className="blob-big-rigth"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
