import './main.scss';
import Consultation from './Consultation/Consultation';
import Services from './Services/Services';
import AboutUs from './AboutUs/AboutUs';
import MixService from './MixService/MixService';
import BusinessSolutions from './BusinessSolutions/BusinessSolutions';
import SubmitApplication from './SubmitApplication/SubmitApplication';
import Projects from './Projects/Projects';
import Advantages from './Advantages/Advantages';
import CustomerReviews from './CustomerReviews/CustomerReviews';
import Partners from './Partners/Partners';

export default function Main() {
  return (
    <main className="main">
      <Consultation type="consultation" />
      <Services />
      <AboutUs />
      <MixService />
      <BusinessSolutions type={'main'} />
      <SubmitApplication />
      <Projects casetype={'main'} />
      <Advantages />
      <CustomerReviews />
      <Partners />
    </main>
  );
}
