import './partners.scss';
import { data } from './data';

export default function Partners() {
  return (
    <div className="partners">
      <div className="wave__top"></div>
      <div className="container">
        <div className="title__list">
          <h2 className="title__item">
            Мы <span> сотрудничаем</span>
          </h2>
          <div className="partners__icons__list">
            {data.map((el, index) => {
              return (
                <img
                  className="partners__icons__item"
                  src={el.img}
                  alt="icon"
                  key={index}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
