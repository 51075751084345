export default function ServiceItem({ items }) {
  const handleClick = (e) => {
    const servicesCard = e.target.closest('.services__card__item');
    if (e.target.closest('.active')) {
      servicesCard.classList.remove('active');
    } else {
      servicesCard.classList.add('active');
    }
  };

  return items.map((el, index) => {
    return (
      <div
        className={`services__card__item services__card__item-${el.id}`}
        key={index}
        onClick={(e) => handleClick(e)}
      >
        <div className="services__card__btn__wrapper">
          <button className="services__card__item__btn">Х</button>
        </div>
        <div className="services__card__info">
          <h5 className="services__card__title">{el.title}</h5>
          <p className="services__card__description">{el.description}</p>
        </div>
      </div>
    );
  });
}
