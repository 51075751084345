import { data } from './data';

export default function AdvantagesItem() {
  return data.map((el, index) => {
    return (
      <div className="avantages__item" key={index}>
        <div className="avantages__item__img-wrapper">
          <img className="avantages__item__img" src={`${el.img}`} alt="img" />
        </div>

        <div className="avantages__item__title">{el.title}</div>
        <div className="avantages__item__discription">{el.discription}</div>
      </div>
    );
  });
}
