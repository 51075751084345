import './submitApplication.scss';
import FormElement from './FormElement/FormElement';
import { useState } from 'react';
import Modal from '../Modal';

export default function SubmitApplication() {
  const [modal, setModal] = useState(false);
  const [setModalActive] = useState(false);

  return (
    <div className="submit__application">
      <div className="wave__top"></div>
      <div className="container">
        <div className="blob__wrapper blob__wrapper-top">
          <div className="blob__list blob__list__left">
            <div className="blob-1"></div>
            <div className="blob-2"> </div>
          </div>
          <div className="blob__list blob_list__rigth">
            <div className="blob-1"></div>
            <div className="blob-2"></div>
          </div>
        </div>

        <div className="title__list">
          <h2 className="title__item">Оставь заявку и получи</h2>
          <h2 className="title__item">бесплатную консультацию!</h2>
        </div>
        <FormElement type={'submit__application'} setModal={setModal} />
        <div className="blob__wrapper blob__wrapper-bottom">
          <div className="blob__list blob__list__left">
            <div className="blob-1"></div>
            <div className="blob-2"> </div>
          </div>
          <div className="blob__list blob_list__rigth">
            <div className="blob-1"></div>
            <div className="blob-2"></div>
          </div>
        </div>

        <Modal active={modal} setActive={setModalActive} setModal={setModal}>
          <h3 className="modal__text">
            Спасибо за заявку. Мы скоро свяжемся с Вами!
          </h3>
          <button
            className="modal__btn__close dark__button"
            onClick={() => {
              setModal(false);
            }}
          >
            Закрыть
          </button>
        </Modal>
      </div>
      <div className="blob__list blob_list__rigth">
        <div className="blob-1"></div>
        <div className="blob-2"></div>
      </div>
      <div className="wave__bottom"></div>
    </div>
  );
}
