import { Swiper, SwiperSlide } from 'swiper/react';
import { data } from './data';
import './customerReviews.scss';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

export default function CustomerReviews() {
  return (
    <div className="customer__reviews">
      <div className="wave__top "></div>
      <div className="container">
        <div className="title__list" id="reviews">
          <h2 className="title__item">отзывы</h2>
          <h2 className="title__item">о нашей работе</h2>
        </div>
        <div className="customer__reviews__slider slider">
          <Swiper
            className="slider"
            spaceBetween={30}
            slidesPerView={'auto'}
            effect={'coverflow'}
            pagination={true}
            centeredslider={'true'}
            loop={true}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              1280: {
                slidesPerView: 3,
              },
              1680: {
                slidesPerView: 3,
              },
            }}
            navigation={{
              nextEl: '.swiper__btn__next',
              prevEl: '.swiper__btn__prev',
              clickable: true,
            }}
            modules={[Navigation]}
          >
            {data.map((el, index) => (
              <SwiperSlide key={index}>
                <div className="slider__item">
                  <div className="quotes"></div>
                  <div className="slider__item__info">
                    <div className="slider__item__review">{el.review}</div>
                    <div className="slider__item__personal__wrapper">
                      <img className="slider__img" src={el.img} alt="face" />
                      <div className="slider__item__personal__info">
                        <div className="slider__name">{el.name}</div>
                        <div className="slider__type">{el.type}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}

            <div className="slider__controler">
              <div className="swiper__btn__prev slider__arrow">
                <ion-icon name="arrow__back__outline"></ion-icon>
              </div>
              <div className="swiper__btn__next slider__arrow">
                <ion-icon name="arrow__forward__outline"></ion-icon>
              </div>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
